<template>
  <el-tabs type="border-card" v-model="activeName">
    <el-tab-pane v-if="tabPan1" name="1">
      <el-tooltip class="item-tabs" effect="dark" placement="bottom" slot="label">
        <div slot="content">
          <span>未成单、未冻结的机会会在此展示</span>
        </div>
        <span>基础机会</span>
      </el-tooltip>
      <OpportunityBank :content-type="1" :SearchNewInformationList="SearchNewInformationList"
                       v-if="tab1Active"></OpportunityBank>
    </el-tab-pane>
    <el-tab-pane v-if="tabPan2" name="2">
      <el-tooltip class="item-tabs" effect="dark" placement="bottom" slot="label">
        <div slot="content">
          <span>有支付成功订单的机会在此展示</span>
        </div>
        <span>成单机会</span>
      </el-tooltip>
      <OpportunityBank :content-type="2" :SearchNewInformationList="SearchNewInformationList"
                       v-if="tab2Active"></OpportunityBank>
    </el-tab-pane>
    <el-tab-pane v-if="tabPan3" name="3" label="冻结机会">
      <el-tooltip class="item-tabs" effect="dark" placement="bottom" slot="label">
        <div slot="content">
          <span>学习顾问选择冻结且主管审核通过的机会在此展示</span>
        </div>
        <span>冻结机会</span>
      </el-tooltip>
      <OpportunityBank :content-type="3" :SearchNewInformationList="SearchNewInformationList"
                       v-if="tab3Active"></OpportunityBank>
    </el-tab-pane>
    <el-tab-pane v-if="tabPan4" name="4" label="全部机会">
      <el-tooltip class="item-tabs" effect="dark" placement="bottom" slot="label">
        <div slot="content">
          <span>全部机会信息,只允许管理员查看</span>
        </div>
        <span>全部机会</span>
      </el-tooltip>
      <OpportunityBank :content-type="3" :SearchNewInformationList="SearchNewInformationList"
                       v-if="tab4Active"></OpportunityBank>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import OpportunityBank from "./components/OpportunityBank"
import {isPermission} from "../../utils/api";
import * as searchApi from "@/api/system/searchDeploy";


export default {
  name: "OpportunityMain",
  components: {
    OpportunityBank
  },
  data() {
    return {
      activeName: "1",
      tab1Active: true,
      tab2Active: false,
      tab3Active: false,
      tab4Active: false,
      tabPan1: false,
      tabPan2: false,
      tabPan3: false,
      tabPan4: false,
      initShow: false,
      SearchNewInformationList: [],
    };
  },
  methods: {
    handleIsPermission: function (pers, tabs) {
      isPermission(pers).then(res => {
        console.log(res)
        switch (tabs) {
          case 1:
            this.tabPan1 = res
            if (!this.initShow) {
              this.activeName = "1"
              this.initShow = true
            }
            this.handleIsPermission("crm:opportunity:order", 2)
            break
          case 2:
            this.tabPan2 = res
            if (!this.initShow) {
              this.activeName = "2"
              this.initShow = true
            }
            this.handleIsPermission("crm:opportunity:freeze", 3)
            break
          case 3:
            this.tabPan3 = res
            if (!this.initShow) {
              this.activeName = "3"
              this.initShow = true
            }
            break

        }
        return res
      })
    },
    initHandleIsPermission: function () {
      this.handleIsPermission("crm:opportunity:basic", 1)

    },

    getSearchNewInformationList: function () {
      searchApi.findByClassify("NEW_INFORMATION").then(res => {
        for (let t of res.data) {
          let tson = JSON.parse(t.data)
          t["tson"] = tson
        }
        this.SearchNewInformationList = res.data;
      });
    },
  },
  computed: {},
  watch: {
    activeName: function (newValue, oldValue) {
      switch (newValue) {
        case "2":
          this.tab2Active = true
          break;
        case "3":
          this.tab3Active = true
          break;
      }
    }
  },
  mounted() {
    this.initHandleIsPermission()

    this.getSearchNewInformationList()
  }
}
</script>

<style scoped>
</style>
